$hex-background: rgba(#fff, 0.99999);
$hex-bg-base: 1px;
$hex-shadow-base: 4px;
$hex-shadow-bg: rgba(0, 0, 0, .25);

@mixin hex-size($width) {
	$widthPercent: ($width / 15);
	$height: $widthPercent * .9;

	width: $widthPercent + 0%;
	padding: $height + 0% 0 0;
}

.hex {
	text-align: center;
	position: relative;
	background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath fill='#{$hex-background}' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E");

	&::after {
		position: absolute;
		content: '';
		height: 110%;
		width: 110%;
		background: url("/images/hexagons-background.png") top center / cover no-repeat;
		top: -5%;
		left: -5%;
		opacity: 0.5;
		z-index: -1;
	}

	&.hex-xxs {
		@include hex-size(45);

		&::after {
			filter: blur($hex-bg-base);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base);
		}
	}

	&.hex-xs {
		@include hex-size(65);

		&::after {
			filter: blur($hex-bg-base);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base);
		}
	}

	&.hex-sm {
		@include hex-size(110);

		&::after {
			filter: blur($hex-bg-base + 1);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base + 1);
		}
	}

	&.hex-md {
		@include hex-size(115);

		&::after {
			filter: blur($hex-bg-base + 2);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base + 2);
		}
	}

	&.hex-lg {
		@include hex-size(161);

		&::after {
			filter: blur($hex-bg-base + 3);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base + 3);
		}
	}

	&.hex-xl {
		@include hex-size(271);

		&::after {
			filter: blur($hex-bg-base + 4);
		}

		&.hex-shadow {
			filter: blur($hex-shadow-base + 4);
		}
	}

	&.hex-border {
		opacity: .3;
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'%3E%3Cpath fill='transparent' stroke='#{$hex-background}' stroke-width='0.1' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/%3E%3C/svg%3E");

		&::after {
			display: none;
		}
	}

	&.hex-shadow {
		&::after {
			display: none;
		}
	}

	.hex-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		.cycle-slide:not(.cycle-sentinel) {
			position: absolute;
			top: 50% !important;
			transform: translateY(-50%) !important;
		}
	}
}
