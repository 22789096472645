/* Sass Variables */

/* Breakpoints */
$grid-breakpoints: (
	xs: 0,
	sm: 568px,
	md: 768px,
	lg: 992px,
	xl: 1280px,
	xxl: 1500px
) !default;

/* Container widths */
$container-max-widths: (
	sm: 520px,
	md: 720px,
	lg: 940px,
	xl: 1220px,
	xxl: 1440px
) !default;

$gutter: 20px;

/* Font sizes */
$base-font-size: 16px;
$base-line-height: 1.4;

// Fonts

$sans-serif: 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$serif: 'Lora', serif;
$exo: 'Exo', $sans-serif;

/* Colours */
$base-font-color: #4f575c;

$white: #fff;
$black: #000;
$lightgrey: #f1f0f1;
$midgrey: #4f575c;
$grey: #697074;
$darkgrey: #333;

$brand-primary: #62bec4;
$brand-secondary: #4f575c;
$brand-tertiary: #aabebf;

/* from 2018 brand guidelines */
$brand-darkgrey: #4f575c;
$brand-silver: #929b9f;
$brand-lightgrey: #f0f0f0;
$brand-limegreen: #b6ce35;
$brand-turquoise: #62bec4;
$brand-gold: #c4a962;
$brand-pink: #e886a3;
$brand-orange: #f49949;
$brand-guest: #a4bebf;
