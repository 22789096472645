.article {
	margin: 0 0 $gutter;

	&:nth-last-of-type(2) {
		margin: 0 0 $gutter*4;
	}

	.article-title {
		text-transform: none;

		a {
			text-decoration: none;
		}
	}

	.article-meta {
		color: darken($lightgrey, 25%);
	}
}
