.faq-accordion {
    transition: ease 0.3s;
    max-width: 910px;
    margin: 0 auto 200px;
    padding: 0;
    list-style: none;

    &--full-width {
        max-width: 100%;
        margin: 0;
    }

    &--chevrons {
        a.toggle {
            position: relative;
            padding-right: 65px;

            &:after {
                position: absolute;
                content: '';
                top: 50%;
                right: 25px;
                transform: translateY(-50%);
                transform-origin: center center;
                height: 20px;
                width: 20px;
                background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%2362BEC4' d='M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z'/%3E%3C/svg%3E") center / contain no-repeat;
                transition: all .2s ease;
            }

            &.active {
                &:after {
                    transform: translateY(-50%) rotate(180deg);
                }
            }
        }
    }

    .inner {
        padding-left: 1em;
        overflow: hidden;
        display: none;
    }

    li {
        margin: 20px 0;

        a.toggle {
            text-decoration: none;
            font-size: 20px;
            font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
            width: 100%;
            display: block;
            background: $lightgrey;
            color: $brand-secondary;
            padding: 20px;
            transition: background .3s ease;

            &:hover,
            &.active {
                background: $brand-secondary;
                color: $lightgrey;
            }
        }
        @media screen and (max-width: 480px) {
            > .inner {
                padding: 10px 20px 20px;
            }

            > .inner li .inner {
                padding: 10px 20px 20px;
            }

            > .inner li .inner p {
                font-size: 14px;
            }

            > a.toggle {
                padding: 10px 20px;
            }
        }
    }

    .inner {
        background: $lightgrey;
        border: 1px solid $brand-secondary;
        border-top: 0;
        list-style: none;
        padding: 30px 30px 50px;

        a.toggle {
            font-size: 16px;
            padding: 8px 20px;
            border-bottom: 1px solid #e4e4e4;
        }

        li {
            margin: 15px 0;
        }

        .inner {
            background: #fff;
            border: 2px solid #e5e3e5;
            border-top: 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
            padding: 15px 30px 30px;
        }
    }
}