.hero {
	text-align: center;
	color: $brand-secondary;
	overflow: hidden;
	position: relative;
	@include media-breakpoint-down(sm) {
		padding: 40px 0;
	}

	&--small {
		overflow-y: hidden;
		height: 180px;
	}

	&.hero-white {
		color: $white;

		.dotted {
			background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'><circle fill='#{$white}' cx='1.5' cy='1.5' r='1.5'/></svg>") center repeat-x;
		}
	}

	&.hero-hex-container {
		@include media-breakpoint-up(md) {
			padding: 10px 0 0;
		}
		@include media-breakpoint-up(lg) {
			padding: 50px 0 0;
		}
		@include media-breakpoint-up(xl) {
			padding: 100px 0 0;
		}
	}

	.hero-content {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		padding: 0 5%;
		margin-bottom: -1rem;
		@include media-breakpoint-up(lg) {
			padding: 0 15%;
		}
		@include media-breakpoint-up(xl) {
			padding: 0 20%;
		}

		.button {
			margin-bottom: 1rem;
		}
	}

	.hero-slides {
		background: #a6bebf;
		padding: 0 0 $gutter;
	}

	.hero-button {
		background: #a6bebf;
	}

	.hero-slide {
		width: 100%;

		p {
			margin: 0 auto $gutter*2;
		}
	}

	.hero-counter {
		margin: 0 auto;

		li {
			height: 18px;
			width: 18px;
			border-radius: 50%;
			background: $white;
			margin: 0 2px;
			opacity: .5;

			&.cycle-pager-active {
				opacity: .8;
			}
		}
	}

	h1,
	p {
		margin: 0 auto;
	}

	p {
		font-size: 18px;
		font-family: $exo;
		max-width: 75%;
		@include media-breakpoint-up(md) {
			max-width: 65%;
			font-size: 22px;
		}
		@include media-breakpoint-up(lg) {
			max-width: 45%;
		}
		@include media-breakpoint-up(xl) {
			max-width: 35%;
		}
	}

	.arrow-down {
		width: 33px;
		transform: rotate(90deg);
		position: relative;
		z-index: 100000;
		margin: 33px auto -33px;
		cursor: pointer;
	}

	.dotted {
		background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'><circle fill='#{$brand-secondary}' cx='1.5' cy='1.5' r='1.5'/></svg>") center repeat-x;
	}

	.hero-hexes {
		position: relative;
		height: 30vw;
		z-index: 10;

		.layer {
			width: 100%;
			height: 100%;
			position: relative;
		}

		/* stylelint-disable */
		.layer {
			.hex {
				position: absolute;
				top: percentage(133/450);
				left: (-40/15) + 0%;
			}

			+ .layer {
				.hex {
					top: percentage(60/450);
					left: (100/15) + 0%;
				}

				+ .layer {
					.hex {
						top: percentage(168/450);
						left: (107/15) + 0%;
					}

					+ .layer {
						.hex {
							top: percentage(50/450);
							left: (230/15) + 0%;
						}

						+ .layer {
							.hex {
								top: percentage(110/450);
								left: (250/15) + 0%;
							}

							+ .layer {
								.hex {
									top: percentage(130/450);
									left: (369/15) + 0%;
								}

								+ .layer {
									.hex {
										top: percentage(165/450);
										left: (553/15) + 0%;
									}

									+ .layer {
										.hex {
											top: percentage(233/450);
											left: (526/15) + 0%;
										}

										+ .layer {
											.hex {
												top: percentage(305/450);
												left: (561/15) + 0%;
											}

											+ .layer {
												.hex {
													top: percentage(274/450);
													left: (633/15) + 0%;
												}

												+ .layer {
													.hex {
														top: percentage(217/450);
														left: (782/15) + 0%;
													}

													+ .layer {
														.hex {
															top: percentage(133/450);
															left: (887/15) + 0%;
														}

														+ .layer {
															.hex {
																top: percentage(140/450);
																left: (1010/15) + 0%;
															}

															+ .layer {
																.hex {
																	top: percentage(250/450);
																	left: (1025/15) + 0%;
																}

																+ .layer {
																	.hex {
																		top: percentage(73/450);
																		left: (1062/15) + 0%;
																	}

																	+ .layer {
																		.hex {
																			top: percentage(160/450);
																			left: (1153/15) + 0%;
																		}

																		+ .layer {
																			.hex {
																				top: percentage(1/450);
																				left: (1183/15) + 0%;
																			}

																			+ .layer {
																				.hex {
																					top: percentage(79/450);
																					left: (1388/15) + 0%;
																				}

																				+ .layer {
																					.hex {
																						top: percentage(280/450);
																						left: (1440/15) + 0%;
																					}
																				}
																			}
																		}
																	}
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		/* stylelint-enable */
	}
}

.mysteryDiningGB.think-gem {
	.hero {
		margin-bottom: 10px;
	}
}

.landing-page {
	.hero {
		padding: 0;
	}
}

.hero-image {
	@include media-breakpoint-up(xl) {
		width: 100%;
	}
}
