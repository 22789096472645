.modal {
	width: 100%;
	max-width: 80%;
	color: $base-font-color;
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	opacity: 0;
	transition: opacity 0.35s ease-in-out;

	&::after {
		position: absolute;
		content: '';
		background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 630 380'><path fill='#{$black}' d='M626.46 166.73L573.6 23.27C568.88 10.47 553.86 0 540.21 0H89.79C76.14 0 61.12 10.47 56.4 23.27L3.54 166.73c-4.72 12.8-4.72 33.75 0 46.55L56.4 356.73C61.12 369.53 76.14 380 89.79 380h450.42c13.65 0 28.67-10.47 33.39-23.27l52.87-143.45c4.71-12.81 4.71-33.75-.01-46.55z'/></svg>") center / cover no-repeat;
		filter: blur(30px);
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: -1;
		opacity: .2;
	}

	.modal-content {
		padding: $gutter;
		width: 100%;
		min-height: 380px;
		background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 630 380'><path fill='#{$white}' d='M626.46 166.73L573.6 23.27C568.88 10.47 553.86 0 540.21 0H89.79C76.14 0 61.12 10.47 56.4 23.27L3.54 166.73c-4.72 12.8-4.72 33.75 0 46.55L56.4 356.73C61.12 369.53 76.14 380 89.79 380h450.42c13.65 0 28.67-10.47 33.39-23.27l52.87-143.45c4.71-12.81 4.71-33.75-.01-46.55z'/></svg>") center / cover no-repeat;

		@include media-breakpoint-up(sm) {
			padding: $gutter * 2 $gutter * 5;
		}
	}

	&-cover {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(255, 255, 255, 0.8);
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.15s ease-in-out;
	}

	&-close {
		position: absolute;
		top: 5px;
		right: 5px;
		font-size: 20px;
		color: $base-font-color;
		text-decoration: none;
		@include media-breakpoint-up(sm) {
			right: 100px;
		}
	}

	h4 {
		background: $brand-secondary;
		color: $white;
		padding: 9px $gutter;
		margin: 0;
	}
}

body.modal-open {
	overflow: hidden;

	.modal-cover {
		opacity: 1;
		transition: opcaity 0.15s ease-in-out;
	}

	.modal {
		opacity: 1;
		transition: opacity 0.35s ease-in-out;
	}
}
