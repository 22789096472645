.support {
	&.guest-home {
		.how-it-works li {
			@media screen and (max-width: 940px) {
				width: 33.3333%;

				.how-it-works-arrow {
					display: none;
				}
			}
			@media screen and (max-width: 660px) {
				width: 50%;
			}
			@media screen and (max-width: 480px) {
				width: 100%;
			}
		}
	}

	.icon-columns {
		list-style: none;
		padding: 0;
		height: auto;
		text-align: center;

		.link-cover {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
		}

		li {
			padding: 60px;
			position: relative;
			float: left;
			margin-bottom: 30px;

			&:nth-of-type(3),
			&:nth-of-type(6),
			&:last-of-type {
				&::after {
					display: none;
				}
			}

			&::after {
				content: "";
				z-index: -1;
				position: absolute;
				right: -11%;
				top: 44%;
				margin: auto;
				background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 246.6 119.1' style='enable-background:new 0 0 246.6 119.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23ABBEBF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M185.6,0c-32.9,0-59.5,26.6-59.5,59.5c0,32.9,26.6,59.5,59.5,59.5c32.9,0,59.5-26.6,59.5-59.5 C245.1,26.6,218.5,0,185.6,0z M172.7,101.1l-5.7-5.7l35.9-35.9l-35.9-35.9l5.7-5.4l41.6,41.2L172.7,101.1z'/%3E%3Cpath class='st0' d='M101.2,58.5c-0.1-0.4-0.2-0.7-0.3-1c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.3-0.4-0.6-0.7-0.8 c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.6-0.4-0.9-0.5c-0.3-0.1-0.6-0.3-1-0.3c-0.7-0.1-1.4-0.1-2.1,0c-0.4,0.1-0.7,0.2-1,0.3 c-0.4,0.1-0.6,0.3-0.9,0.5c-0.3,0.2-0.6,0.4-0.8,0.7c-0.3,0.3-0.5,0.5-0.7,0.8c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.3-0.2,0.6-0.3,1 c-0.1,0.3-0.1,0.7-0.1,1c0,0.7,0.1,1.4,0.4,2c0.3,0.7,0.7,1.3,1.2,1.8c0.5,0.5,1.1,0.9,1.8,1.2c0.6,0.3,1.3,0.4,2,0.4 c1.4,0,2.8-0.6,3.8-1.6c0.5-0.5,0.9-1.1,1.2-1.8c0.3-0.6,0.4-1.3,0.4-2C101.3,59.2,101.3,58.8,101.2,58.5z'/%3E%3Ccircle class='st0' cx='60.1' cy='59.5' r='5.4'/%3E%3Ccircle class='st0' cx='24.3' cy='59.5' r='5.4'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				width: 87px;
				height: 42px;
			}

			img {
				max-width: 220px;
				margin: auto;
			}

			@media screen and (max-width: 940px) {
				width: 50%;

				&:nth-of-type(3),
				&:nth-of-type(6) {
					&::after {
						display: block;
					}
				}

				&:nth-of-type(2),
				&:nth-of-type(4),
				&:nth-of-type(6) {
					&::after {
						display: none;
					}
				}
			}

			@media screen and (max-width: 540px) {
				width: 100%;
				height: auto !important;
				padding: 40px 60px 20px;

				img {
					max-width: 160px;
				}

				&::after {
					content: "";
					position: absolute;
					transform: rotate(90deg);
					height: 8%;
					width: 80px;
					right: 0%;
					left: 0;
					bottom: -250px;
					margin: auto;
					background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 246.6 119.1' style='enable-background:new 0 0 246.6 119.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23ABBEBF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M185.6,0c-32.9,0-59.5,26.6-59.5,59.5c0,32.9,26.6,59.5,59.5,59.5c32.9,0,59.5-26.6,59.5-59.5 C245.1,26.6,218.5,0,185.6,0z M172.7,101.1l-5.7-5.7l35.9-35.9l-35.9-35.9l5.7-5.4l41.6,41.2L172.7,101.1z'/%3E%3Cpath class='st0' d='M101.2,58.5c-0.1-0.4-0.2-0.7-0.3-1c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.3-0.4-0.6-0.7-0.8 c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.6-0.4-0.9-0.5c-0.3-0.1-0.6-0.3-1-0.3c-0.7-0.1-1.4-0.1-2.1,0c-0.4,0.1-0.7,0.2-1,0.3 c-0.4,0.1-0.6,0.3-0.9,0.5c-0.3,0.2-0.6,0.4-0.8,0.7c-0.3,0.3-0.5,0.5-0.7,0.8c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.3-0.2,0.6-0.3,1 c-0.1,0.3-0.1,0.7-0.1,1c0,0.7,0.1,1.4,0.4,2c0.3,0.7,0.7,1.3,1.2,1.8c0.5,0.5,1.1,0.9,1.8,1.2c0.6,0.3,1.3,0.4,2,0.4 c1.4,0,2.8-0.6,3.8-1.6c0.5-0.5,0.9-1.1,1.2-1.8c0.3-0.6,0.4-1.3,0.4-2C101.3,59.2,101.3,58.8,101.2,58.5z'/%3E%3Ccircle class='st0' cx='60.1' cy='59.5' r='5.4'/%3E%3Ccircle class='st0' cx='24.3' cy='59.5' r='5.4'/%3E%3C/svg%3E");
					background-repeat: no-repeat;
					z-index: -1;
				}

				&:nth-of-type(1n) {
					&::after {
						display: block;
					}
				}
			}
		}

		.down-arrow {
			position: relative;
			float: none;
			clear: both;
			height: 43px;
			display: none;
			margin: 0 auto 20px;
			width: 66%;
			background-image: url(/images/dots.svg);
			background-repeat: repeat-x;
			background-size: auto 100%;
			background-position: center;

			@media screen and (max-width: 940px) {
				width: 50%;
			}

			&::before {
				content: "";
				transform: rotate(90deg);
				position: absolute;
				height: 44px;
				width: 85px;
				left: -48px;
				bottom: -36px;
				margin: auto;
				background-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_2' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 246.6 119.1' style='enable-background:new 0 0 246.6 119.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23ABBEBF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M185.6,0c-32.9,0-59.5,26.6-59.5,59.5c0,32.9,26.6,59.5,59.5,59.5c32.9,0,59.5-26.6,59.5-59.5 C245.1,26.6,218.5,0,185.6,0z M172.7,101.1l-5.7-5.7l35.9-35.9l-35.9-35.9l5.7-5.4l41.6,41.2L172.7,101.1z'/%3E%3Cpath class='st0' d='M101.2,58.5c-0.1-0.4-0.2-0.7-0.3-1c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.3-0.4-0.6-0.7-0.8 c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.6-0.4-0.9-0.5c-0.3-0.1-0.6-0.3-1-0.3c-0.7-0.1-1.4-0.1-2.1,0c-0.4,0.1-0.7,0.2-1,0.3 c-0.4,0.1-0.6,0.3-0.9,0.5c-0.3,0.2-0.6,0.4-0.8,0.7c-0.3,0.3-0.5,0.5-0.7,0.8c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.3-0.2,0.6-0.3,1 c-0.1,0.3-0.1,0.7-0.1,1c0,0.7,0.1,1.4,0.4,2c0.3,0.7,0.7,1.3,1.2,1.8c0.5,0.5,1.1,0.9,1.8,1.2c0.6,0.3,1.3,0.4,2,0.4 c1.4,0,2.8-0.6,3.8-1.6c0.5-0.5,0.9-1.1,1.2-1.8c0.3-0.6,0.4-1.3,0.4-2C101.3,59.2,101.3,58.8,101.2,58.5z'/%3E%3Ccircle class='st0' cx='60.1' cy='59.5' r='5.4'/%3E%3Ccircle class='st0' cx='24.3' cy='59.5' r='5.4'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				z-index: -1;
			}

			&::after {
				content: "";
				transform: rotate(90deg);
				position: absolute;
				height: 41px;
				width: 85px;
				right: -48px;
				top: -39px;
				margin: auto;
				background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 246.6 119.1' style='enable-background:new 0 0 246.6 119.1;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23ABBEBF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M245.7,58.3c-0.1-0.4-0.2-0.7-0.3-1c-0.1-0.3-0.3-0.6-0.5-0.9c-0.2-0.3-0.4-0.6-0.7-0.8 c-0.2-0.3-0.5-0.5-0.8-0.7c-0.3-0.2-0.6-0.4-0.9-0.5c-0.3-0.1-0.6-0.3-1-0.3c-0.7-0.1-1.4-0.1-2.1,0c-0.4,0.1-0.7,0.2-1,0.3 c-0.4,0.1-0.6,0.3-0.9,0.5c-0.3,0.2-0.6,0.4-0.8,0.7c-0.3,0.3-0.5,0.5-0.7,0.8c-0.2,0.3-0.4,0.6-0.5,0.9c-0.1,0.3-0.2,0.6-0.3,1 c-0.1,0.3-0.1,0.7-0.1,1c0,0.7,0.1,1.4,0.4,2c0.3,0.7,0.7,1.3,1.2,1.8c0.5,0.5,1.1,0.9,1.8,1.2c0.6,0.3,1.3,0.4,2,0.4 c1.4,0,2.8-0.6,3.8-1.6c0.5-0.5,0.9-1.1,1.2-1.8c0.3-0.6,0.4-1.3,0.4-2C245.9,59,245.8,58.6,245.7,58.3z'/%3E%3Cpath class='st0' d='M25.5,53.9c-2.9,0-5.4,2.4-5.4,5.4c0,3,2.4,5.4,5.4,5.4c3,0,5.4-2.4,5.4-5.4C30.9,56.3,28.5,53.9,25.5,53.9z'/%3E%3Ccircle class='st0' cx='204.6' cy='59.3' r='5.4'/%3E%3Ccircle class='st0' cx='168.8' cy='59.3' r='5.4'/%3E%3Ccircle class='st0' cx='133' cy='59.3' r='5.4'/%3E%3Ccircle class='st0' cx='97.2' cy='59.3' r='5.4'/%3E%3Cpath class='st0' d='M61.3,53.9c-2.9,0-5.4,2.4-5.4,5.4c0,3,2.4,5.4,5.4,5.4c3,0,5.4-2.4,5.4-5.4C66.7,56.3,64.3,53.9,61.3,53.9z'/%3E%3Ccircle class='st0' cx='-618.9' cy='59.5' r='5.4'/%3E%3Ccircle class='st0' cx='-583.1' cy='59.5' r='5.4'/%3E%3C/svg%3E");
				background-repeat: no-repeat;
				z-index: -1;
			}

			&:nth-of-type(3n+3) {
				display: block;
			}

			&:last-of-type {
				display: none;
			}

			@media screen and (max-width: 940px) {
				&:nth-of-type(3n+3) {
					display: none;
				}

				&:nth-of-type(2n+2) {
					display: block;
				}

				&:last-of-type {
					display: none;
				}
			}

			@media screen and (max-width: 540px) {
				&:nth-of-type(2n+2) {
					display: none;
				}
			}
		}
	}

	.faq-accordion {
		transition: ease 0.3s;
		max-width: 910px;
		margin: 0 auto 200px;
		padding: 0;
		list-style: none;

		.inner {
			padding-left: 1em;
			overflow: hidden;
			display: none;
		}

		li {
			margin: 20px 0;

			a.toggle {
				text-decoration: none;
				font-size: 20px;
				font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
				width: 100%;
				display: block;
				background: $lightgrey;
				color: $brand-secondary;
				padding: 20px;
				transition: background .3s ease;

				&:hover,
				&.active {
					background: $brand-secondary;
					color: $lightgrey;
				}
			}
			@media screen and (max-width: 480px) {
				> .inner {
					padding: 10px 20px 20px;
				}

				> .inner li .inner {
					padding: 10px 20px 20px;
				}

				> .inner li .inner p {
					font-size: 14px;
				}

				> a.toggle {
					padding: 10px 20px;
				}
			}
		}

		.inner {
			background: $lightgrey;
			border: 1px solid $brand-secondary;
			border-top: 0;
			list-style: none;
			padding: 30px 30px 50px;

			a.toggle {
				font-size: 16px;
				padding: 8px 20px;
				border-bottom: 1px solid #e4e4e4;
			}

			li {
				margin: 15px 0;
			}

			.inner {
				background: #fff;
				border: 2px solid #e5e3e5;
				border-top: 0;
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 8px;
				padding: 15px 30px 30px;
			}
		}
	}

	.list-blocks {
		margin: auto;

		a {
			text-decoration: none;
		}

		img {
			max-width: 100%;
		}

		li {
			width: 20%;
			margin-bottom: 10px;

			/*
			this was causing issues with list boxes on the homepage
			Not sure why it's here so commenting it out for now

				&:nth-of-type(3) {
					&::after {
						display: none;
					}
				}
			*/

			@media (max-width: 900px) {
				width: 33.333%;

					&::after {
						display: none;
					}
			}

			@media (max-width: 370px) {
				width: 100%;
			}
		}
	}
}

.col-asymmetric {
	margin-bottom: 200px;
	overflow: auto;

	.col {
		float: left;
		width: 37.5%;

		&:first-of-type {
			width: 62.5%;
			padding-right: 60px;
		}

		&:last-of-type {
			padding-left: 60px;

			img {
				width: 100%;
			}
		}

		&.full-width {
			width: 100%;
		}
	}

	&.general-layout {
		max-width: 940px;
		margin: 0 auto 200px;
	}

	@media screen and (max-width: 740px) {
		.col {
			&:last-of-type,
			&:first-of-type {
				padding: 0;
				width: 100%;
				float: none;
			}
		}
	}
}

.header-menu.list-reset.list-inline {
	position: relative;

	.nav-dropdown {
		cursor: pointer;

		ul {
			transition: 0.3s ease;
			padding: 40px 10px 20px;
			position: absolute;
			z-index: 0;
			text-align: left;
			background: #4f575c;
			width: 200px;
			right: -20px;
			max-height: 0;
			opacity: 0;
			overflow: hidden;

			li {
				display: block;
				margin: 0;
				max-height: inherit;
				position: relative;
				padding: 2px 0;
				font-size: 16px;

				&:first-of-type {
					padding-top: 10px;
					/* stylelint-disable */
					&::after {
						content: '';
						height: 1px;
						background: #fff;
						width: 120%;
						z-index: 1;
						position: absolute;
						top: -4px;
						left: -10px;
						right: -10px;
					}
					/* stylelint-enable */
				}

				a {
					display: block;
				}
			}
		}

		&:hover {
			ul {
				padding: 40px 10px 25px;
				max-height: 200px;
				opacity: 1;
				z-index: 1;
			}
		}

		@media screen and (max-width: 992px) {
			ul {
				padding: 0 10px 25px;
				position: relative;
				z-index: 0;
				text-align: center;
				background: transparent;
				width: auto;
				right: 0;
				max-height: 100%;
				opacity: 1;
				margin: 0;
			}

			ul li::after {
				display: none;
			}

			&:hover {
				ul {
					padding: 0 10px 25px;
					max-height: 100%;
				}
			}
		}
	}
}

header {
	nav {
		left: 0;
		right: 0;

		@media screen and (min-width: 992px) {
			margin: 0;
		}
	}

	nav.support-nav,
	nav.guest-nav {
		@media screen and (min-width: 992px) {
			display: block !important;
		}

		ul {
			float: right;

			&:nth-of-type(1) {
				margin-left: 20px;
			}

			&:last-of-type {
				clear: both;
				float: none;
			}

			@media screen and (max-width: 992px) {
				float: none;

				.social {
					margin-top: 20px;
				}
			}
		}

		li.social svg {
			background: 0;
			margin-bottom: -0.5em;

			path {
				fill: #a6abad;
			}
		}

		@media screen and (max-width: 992px) {
			border-top: 3px solid #fff;
			margin: 0;
			background: #4f575c;

			ul {
				&:nth-of-type(1) {
					margin-left: 0;
					margin-top: 20px;
				}

				li a {
					color: #fff;
				}
			}
		}
	}

	nav.guest-nav {
		.header-menu.list-reset.list-inline {
			display: block;

			h4 {
				display: inline;
			}
		}
	}
}

.hamburger {
	padding: 0;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, -webkit-filter;
	transition-property: opacity, filter;
	transition-property: opacity, filter, -webkit-filter;
	transition-duration: .15s ease;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible;

	&.active {
		.hamburger-inner {
			transform: translate3d(0, 8px, 0) rotate(45deg);
		}

		.hamburger-inner::before {
			opacity: 0;
		}

		.hamburger-inner::after {
			transform: translate3d(0, -20px, 0) rotate(90deg);
		}
	}

	.hamburger-box {
		width: 40px;
		height: 24px;
		display: inline-block;
		position: relative;
	}

	.hamburger-inner {
		top: 2px;
		display: block;
		margin-top: -2px;
		width: 40px;
		height: 4px;
		background-color: #fff;
		border-radius: 4px;
		position: absolute;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;

		&::before,
		&::after {
			content: "";
			width: 40px;
			height: 4px;
			background-color: #fff;
			border-radius: 4px;
			position: absolute;
			transition-property: transform;
			transition-duration: 0.15s;
			transition-timing-function: ease;
		}

		&::before {
			top: 10px;
			transition-property: transform, opacity;
			transition-timing-function: ease;
			transition-duration: 0.2s;
		}

		&::after {
			top: 20px;
		}
	}
}
