#ccc {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 100;
	pointer-events: none;

	// &:has(.ccc-banner) {
	// 	background-color: rgba(0,0,0,0.3);
	// }

	.ccc-banner {
		pointer-events: all;
		position: relative !important;
		top: unset !important;
		bottom: unset !important;
		left: unset !important;
		right: unset !important;
		width: 100%;
		max-width: calc(100% - 3rem);
		margin: 0 auto;

		@media (min-width: 700px) {
			max-width: 620px;
		}
	}
}

