.landing-page {
	footer {
		background: $brand-secondary;
		color: $white;
	}

	p,h1,h2,h3,h4,h5,h6,li {
		a {
			color: #aabebf;
		}
	}


	header {
		border-bottom: 5px solid $brand-turquoise;

		.header-menu,
		.hgem-buttons {
			display: none;
		}

		.guest-buttons {
			display: block;

			li {
				&:last-of-type {
					a {
						background: $brand-silver;
					}
				}

				a {
					background: $brand-turquoise;
					color: #fff;
				}
			}
		}
	}

	.hero {
		
		.js-bg-img {
			width: unset;
		}

		.dotted {
			display: none;
		}

		h1 {
			line-height: 1;
		}

		.button {
			margin-left: 5px;
			margin-right: 5px;
		}

		p {
			margin-bottom: 20px;
			font-size: 18px;
			font-family: $exo;
			max-width: 75%;
			@include media-breakpoint-up(md) {
				font-size: 22px;
			}
		}
	}


	&__block {
		&--copy {
			margin-bottom: 2rem;
			/*
				p:first-of-type {
					font-size: 120%;
				}

			*/

			.big-text {
				font-size: 120%;
			}

			h2.small {
				font-size: $base-font-size;
				font-weight: bold;
				text-transform: inherit;
			}
		}

		&--image,
		&--imageCta {
			margin-bottom: 2rem;

			.image-row {
				display: flex;
				flex-flow: row wrap;
				margin: 0 0 -1rem;

				@include media-breakpoint-up(md) {
					margin: 0 -1rem -1rem;
				}

				&--cta {
					margin-bottom: -1rem;

					.image-row__imageWrapper {
						flex: 0 0 100%;
						@include media-breakpoint-up(md) {
							flex: 1;
						}
					}

					.image-row__image {
						@include media-breakpoint-up(md) {
							height: 0;
							padding-bottom: calc(100% - 2rem);
						}
					}
				}

				&__image {
					position: relative;
					flex: 0 0 100%;
					margin: 0 0 1rem;

					@include media-breakpoint-up(md) {
						flex: 1;
						margin: 0 1rem 1rem;
					}

					&--dupe {
						@include media-breakpoint-up(md) {
							height: 0;
							padding-bottom: calc(50% - 2rem);
						}

						img {
							@include media-breakpoint-up(md) {
								position: absolute;
								top: 0;
								left: 0;
								height: 100%;
								width: 100%;
								object-fit: cover;
							}
						}
					}
				}

				&__cta {
					text-align: center;
					margin-bottom: 1rem;
				}
			}
		}

		&--footnotes {
			p {
				font-size: 80%
			}

			a {
				color: #62bec4;
			}
		}

		&--textColumns {
			margin-bottom: 2rem;

			.textColumns {

				&__outer {
					display: flex;
					flex-flow: row wrap;
					margin: 0 -1rem;
				}
	
				&__box {
					flex: 0 0 100%;
					@include media-breakpoint-up(md) {
						flex: 0 0 50%;
					}
				}
	
				&__box-inner {
					margin: 0 1rem;
	
					&--tick {
						position: relative;
						margin-left: 3.05rem;
	
						&:before {
							content: '';
							position: absolute;
							top: 2px;
							left: -2rem;
							height: 20px;
							width: 20px;
							background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath fill='%23AABFBF' d='M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.959 17l-4.5-4.319 1.395-1.435 3.08 2.937 7.021-7.183 1.422 1.409-8.418 8.591z'/%3E%3C/svg%3E") center / contain no-repeat;
						}
					}
	
					h3 {
						font-size: $base-font-size;
						text-transform: inherit;
						font-weight: bold;
						margin-bottom: -6px;
					}
				}
			}
		}
	}

	&__builder-content {
		width: 100%;
		padding-right: 2rem;
		margin-bottom: 1rem;

		@include media-breakpoint-up(lg) {
			width: 65%;
			max-width: 65%;
			margin-bottom: 0;
		}

		&--full-width {
			width: 100%;
			max-width: 920px;
			padding: 0;
			margin: auto;
		}
	}

	&__footer-cta {
		background: $brand-turquoise;
		color: #fff;
		padding: 3rem 2rem;
		margin-top: 2rem;
		text-align: center;
	}

	&__footer-cta-button {
		font-size: 130%;
		color: $brand-secondary
	}

	&__footer-cta-text {
		font-size: 130%;
		margin-bottom: 1rem;
	}

	&__how-it-works {
		position: relative;
		padding-left: 2rem;

		&::before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3 '%3E%3Ccircle fill='rgba(#{red($brand-tertiary)}, #{green($brand-tertiary)}, #{blue($brand-tertiary)}, .99999)' cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E") center repeat-x;
			height: 3px;
			width: 100%;
			@include media-breakpoint-up(md) {
				background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 10'%3E%3Ccircle fill='rgba(#{red($brand-tertiary)}, #{green($brand-tertiary)}, #{blue($brand-tertiary)}, .99999)' cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E") center repeat-y;
				height: 100%;
				width: 3px;
			}
		}

		width: 100%;

		@include media-breakpoint-up(lg) {
			width: 35%;
			max-width: 35%;
		}

		&-list {
			list-style: none;
			margin: 0;
			padding: 0;
		}

		&-icon {
			background-color: $brand-tertiary;
			width: 40%;
			height: 100px;
			max-width: 100px;
			margin-right: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				width: 80px;
				height: 80px;
				max-width: 80px;
			}
		}

		&-text {
			width: 60%;
			max-width: 60%;
		}

		h4 {
			margin: 0;
		}

		li {
			display: flex;
			flex-direction: row;
			align-items: start;
			margin-bottom: 1rem;
		}
	}

	&__container {
		display: flex;
		flex-direction: column;

		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}
	}

	&__footer-images {
		width: 100%;
		margin: 2rem 0;
		display: flex;
		flex-grow: 1;
		align-items: flex-start;
		justify-items: space-between;
	}

	&__footer-image {
		min-width: 20%;
		padding-right: 1rem;
		&:last-child {
			margin-right: 0;
		}
		/*
		margin-right: 1rem;
		&:last-child {
			margin-right: 0;
		}
		*/

		img {
			display: block;
			max-width: 100%;
			width: 100%;
		}
	}

	&__rhc-cta {
		margin: 30px 0 10px;
	}
}
