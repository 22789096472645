.testimonial {
    position: relative;

    &__columns {
        align-items: center;
    }

    &__column {
        display: flex!important;
        flex-flow: column;
        align-items: center;

        @include media-breakpoint-up(sm) {
            display: inline-block!important;
            padding: 0 3rem!important;
        }

        p {
            font-size: 1.35rem;

            &:last-of-type {
                margin-bottom: 2rem;
            }
        }

        blockquote {
            position: relative;
            &:before,
            &:after {
                color: $brand-turquoise;
                content: open-quote;
                font-size: 4em;
                margin-right: 0.25em;
                vertical-align: -0.4em;
                left: -31px;
                top: -25px;
                position: absolute;
            }

            &:after {
                content: close-quote;
                top: inherit;
                bottom: 0;
                left: inherit;
                right: -31px;
            }
        }
    }
}