
.search-bar {
	background: rgba(241, 240, 241, 1);
	margin: 0;
	transition: 0.3s ease;

	.button,
	.button:hover {
		min-height: 38px;
		width: 90%;
		margin-left: 10%;
		background-image: url('/images/search-icon.png');
		background-repeat: no-repeat;
		background-position: center;
		background-size: 20px;
		background-color: $brand-turquoise;
		border-color: $brand-turquoise;
	}

	form {
		display: flex;
	}

	.form__row {
		&:first-of-type {
			width: 85%;
		}
	
		&:last-of-type {
			width: 15%;
		}
	}
	@media screen and (max-width: 630px) {
		form .form__row {
			&:first-of-type {
				width: 75%;
			}

			&:last-of-type {
				width: 25%;
			}
		}
	}
}