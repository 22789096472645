/*
Excuse the use of !important
Need to get this onto the support site quick and serious specificity issues abound.
Needs a refactor to sort the specificity
Pete
*/
.lang-selector {
	background: transparent;
	//width: 80px;
	border-radius: 0;
	padding: 8px 0;
	text-align: center;
	position: relative;
	font-size: 16px!important;
	margin: 0 0.5rem 0 4px;

	&:hover {
		border-radius: 0;
	}

	&:hover .lang-selector__list {
		display: block;
	}

	&__current {
		text-transform: uppercase;

		img {
			transform: translateY(10px);
		}
	}

	&__list {
		display: none;
		position: absolute;
		width: 100%!important;
		padding: 0!important;
		margin: 10px 0 0!important;
		z-index: 98!important;
		background: #fff;
	}

	&__list-option {
		margin: 0!important;
		width: 100%!important;
	}

	&__list-option-link {
		background: #fff!important;
		border-radius: 0!important;
		color: #aabebf!important;
		width: 100%!important;

		&--last {
			border-radius: 0 0 3px 3px;
		}
	}

	&__flag {
		max-width: 40px;
		width: 40px;
	}
}
