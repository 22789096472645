.guest-home {
	background: $brand-guest url(/images/bg-guest-home.jpg) top center / contain no-repeat;

	@media (max-width: 500px) {
		background-size: 600px;
	}

	header {
		background: transparent;

		.header-menu,
		.hgem-buttons {
			display: none;
		}

		.guest-buttons {
			&--mobile {
				margin: -2px auto 0;
				@include media-breakpoint-up(md) {
					display: none;
				}
			}

			&--desktop {
				display: none;
				@include media-breakpoint-up(md) {
					display: block;
				}
			}

			li {
				&:last-of-type {
					a {
						background: $brand-silver;
					}
				}

				a {
					background: $brand-turquoise;
				}
			}
		}
	}

	.list-blocks {
		h4 {
			background: #62bec4;
		}
	}

	.hero {
		color: $white;

		p {
			font-size: 36px;
		}
	}

	h1 {
		max-width: 750px;
		margin-bottom: 35px;
		line-height: 1.3;
	}

	.hero-slides .hero-slide {
		p {
			margin-top: 25px;
			line-height: 1.3;
			max-width: 750px;
		}

		img {
			max-width: 140px;
		}
	}

	.how-it-works {
		.list-icon {
			max-width: 120px;
			margin: 25px auto;
		}

		&.list-blocks li {
			&::before {
				background: #fff;
				height: 100%;
				width: 10px;
				left: 0;
			}

			&::after {
				background: #fff;
				height: 100%;
				width: 10px;
				right: 0;
				border-top: 0;
				border-bottom: 0;
				border-left: 0;
				top: 0;
			}

			&:first-of-type .how-it-works-arrow {
				display: none;
			}
		}

		.how-it-works-arrow {
			border-top: 12px solid transparent;
			border-bottom: 12px solid transparent;
			border-left: 12px solid #f1f0f1;
			top: 152px;
			left: -5px;
			z-index: 10;
			position: absolute;
		}
	}

	.hero.hero-hex-container {
		padding: 20px 0 0;
	}

	.icon-list-large {
		max-width: calc((220px + 20px * 4) * 3);

		li {
			max-width: 220px;

			img {
				width: 100%;
			}
		}
	}
}

.blog-email-signup-form {
	font-size: 16px;
	padding: 0 30px;

	.form__row {
		margin-bottom: 25px;
	}

	.form__label {
		z-index: 1;
		margin-bottom: -35px;
		position: relative;
		margin-left: 10px;
		text-align: left;
	}

	.form__note {
		display: none;
	}

	.box-narrow {
		transition: 0.5s ease;
		max-height: 0;
		margin-bottom: 0 !important;
		padding: 0;
		overflow: hidden;
		opacity: 0;

		&.active {
			max-height: 350px;
			opacity: 1;
		}
	}
}

.blog-email-signup-form {
	margin-top: -70px;
	font-size: 16px;
	padding: 0 30px;

	.form__row {
		margin-bottom: 25px;
	}

	.form__label {
		z-index: 1;
		margin-bottom: -35px;
		position: relative;
		margin-left: 10px;
		text-align: left;
	}

	.form__note {
		display: none;
	}

	fieldset {
		border: none;
	}

	.button {
		background: #c4a962;
		color: #fff;
		border: 1px solid #fff;
		transition: ease 0.5s;

		&:hover {
			border: 1px solid #c4a962;
			background: #fff;
			color: #c4a962;
		}
	}

	.box-narrow {
		transition: 0.5s ease;
		max-height: 0;
		margin-bottom: 0 !important;
		padding: 0;
		overflow: hidden;
		opacity: 0;

		&.active {
			max-height: 350px;
			opacity: 1;
		}
	}
}
