.service {
	.hero {
		background-color: #505d65 !important;
		overflow: hidden;
		position: relative;

		.grayscale {
			opacity: 0.8;
			-webkit-filter: grayscale(1);
			filter: grayscale(1);
		}

		.hero-content {
			z-index: 3;
		}

		.overlay {
			.gold-grad {
				z-index: 2;
				height: 200px;
				left: 0;
				right: 0;
				bottom: -10%;
				margin: auto;
				position: absolute;
				background: -moz-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(196, 169, 98, 1) 100%);
				background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(196, 169, 98, 1) 100%);
				background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(196, 169, 98, 1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#c4a962', GradientType=0);
			}

			svg {
				z-index: 1;
				fill: #fff;
				position: absolute;
				left: -20%;
				right: -20%;
				top: 0;
				bottom: 0;
				margin: auto;
				width: 140%;
				opacity: 0.12;
			}
		}

		@media (max-width: 767px) {
			padding: 0;
			min-height: 220px;

			.grayscale {
				right: 0;
				position: absolute;
				height: 100%;
				max-width: none;
				width: auto;
			}

			.overlay svg {
				left: -100%;
				right: -100%;
				width: 300%;
			}
		}
	}

	@media (min-width: 768px) {
		.columns-two .column {
			width: 50%;
			padding: 0 60px;
		}
	}
}
