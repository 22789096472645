.quote-block {

    &__blockquote {
        background: #d5d8d9;
        border-left: 5px solid #b6ce35;
        margin: 20px 25px;
        padding: 1.5rem 1rem;
        quotes: "\201C""\201D""\2018""\2019";

        &:before {
            color: #50575c;
            content: open-quote;
            font-size: 4em;
            line-height: 0.1em;
            margin-right: 0.25em;
            vertical-align: -0.4em;
        }

        p {
            display: inline;
        }

        footer {
            background: inherit;
            color: inherit;
            padding: 1rem 0 0 1rem;
        }
    }
}