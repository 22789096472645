.image-overlay {
	position: relative;
	cursor: pointer;

	&::after {
		content: '';
		width: 100%;
		height: 100%;
		display: block;
		background: $brand-secondary;
		opacity: .6;
		position: absolute;
		top: 0;
		left: 0;
	}

	&.overlay-reverse {
		&::after {
			opacity: 0;
			transition: opacity 500ms;
		}

		.overlay-content {
			h3,
			h4,
			p {
				text-shadow: 0 0 6px rgba(0, 0, 0, 1);
			}
		}
	}

	&.image-grow {
		overflow: hidden;

		img {
			transition: transform 500ms;
		}
	}

	.overlay-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		color: $white;
		z-index: 10;
		width: 95%;

		&.overlay-narrow {
			@include media-breakpoint-up(md) {
				width: 95%;
			}
			@include media-breakpoint-up(lg) {
				width: 75%;
			}
			@include media-breakpoint-up(xl) {
				width: 45%;
			}
		}

		&.overlay-two-thirds {
			top: 75%;
			opacity: 1;
			transition: opacity 500ms;

			h3,
			h4,
			p {
				margin: 0;
			}
		}
	}

	.overlay-hover {
		position: absolute;
		bottom: 0;
		width: 100%;
		height: 0;
		transition: height 0.333s ease-out;
		background: $brand-primary;
		color: $white;
		z-index: 100;
		overflow: hidden;
		text-align: center;

		&.hover-transparent {
			background: transparent;
		}

		.hover-content {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 95%;

			&.hover-narrow {
				@include media-breakpoint-up(md) {
					width: 95%;
				}
				@include media-breakpoint-up(lg) {
					width: 75%;
				}
				@include media-breakpoint-up(xl) {
					width: 45%;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			p {
				font-size: 14px;
			}
		}
	}

	.overlay-content,
	.overlay-hover {
		img {
			max-width: 200px;
			margin: 0 auto;
		}
	}

	&:hover {
		.overlay-hover {
			height: 100%;
		}

		&.overlay-reverse {
			&::after {
				opacity: .6;
				transition: opacity 500ms;
			}
		}

		&.image-grow {
			img {
				transition: transform 500ms;
				transform: scale(1.2);
			}
		}

		.overlay-two-thirds {
			opacity: 0;
			transition: opacity 500ms;
		}
	}

	h3,
	h4 {
		text-transform: uppercase;
	}
}
