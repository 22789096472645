.tip-container {
	position: absolute;
	background: $white;
	padding: $gutter;
	border-radius: $gutter;
	box-shadow: 0 0 10px rgba(0, 0, 0, .5);
	width: 40%;
	@include media-breakpoint-up(lg) {
		width: 25%;
	}
}
