.circle-container {
	position: relative;
}

.circle-overlay {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	z-index: 100;
}

.circle {
	max-width: 618px;
	width: 100%;
	overflow: visible !important;
	margin: auto;
	display: block;

	.cls-1,
	.cls-2,
	.cls-3,
	.cls-4 {
		cursor: pointer;
	}

	.cls-1 {
		fill: $white;

		path {
			&:first-child {
				fill: #7a9393;
			}
		}
	}

	.cls-2 {
		fill: $white;

		path {
			&:first-child {
				fill: #8e8878;
			}
		}
	}

	.cls-3 {
		fill: $white;

		path {
			&:first-child {
				fill: #91a38b;
			}
		}
	}

	.cls-4 {
		fill: $white;

		path {
			&:first-child {
				fill: #b7ac93;
			}
		}
	}

	.cls-6 {
		fill: $white;

		path {
			&:first-child {
				fill: #aabebf;
			}
		}
	}

	.cls-7 {
		fill: $white;

		path {
			&:first-child {
				fill: #c5c1b3;
			}
		}
	}

	.cls-8 {
		fill: $white;

		path {
			&:first-child {
				fill: #c8d1c5;
			}
		}
	}

	.cls-9 {
		fill: $white;

		path {
			&:first-child {
				fill: #e1d7bd;
			}
		}
	}

	.cls-10 {
		fill: $white;

		path {
			&:first-child {
				fill: #939c9f;
			}
		}
	}

	&.circle-hover {
		.cls-1 {
			&:hover {
				path {
					&:first-child {
						fill: darken(#7a9393, 10%);
					}
				}
			}
		}

		.cls-2 {
			&:hover {
				path {
					&:first-child {
						fill: darken(#8e8878, 10%);
					}
				}
			}
		}

		.cls-3 {
			&:hover {
				path {
					&:first-child {
						fill: darken(#91a38b, 10%);
					}
				}
			}
		}

		.cls-4 {
			&:hover {
				path {
					&:first-child {
						fill: darken(#b7ac93, 10%);
					}
				}
			}
		}
	}

	&.circle-expand {
		max-width: 500px;
		margin: 0 auto $gutter;

		.circle-sector-1,
		.circle-sector-2,
		.circle-sector-3,
		.circle-sector-4 {
			opacity: 0;
			transition: all 500ms;
		}

		.circle-sector-1,
		.circle-sector-3 {
			transform: translateX(-150px);
		}

		.circle-sector-2,
		.circle-sector-4 {
			transform: translateX(150px);
		}

		.circle-sector-animated {
			transform: translateX(0);
			opacity: 1;
			transition: all 500ms;
		}

		.circle-centre {
			transform: scale(0);
			transform-origin: center;
			transition: all 500ms;
		}

		.circle-centre-animated {
			transform: scale(1);
			transform-origin: center;
			transition: all 500ms;
		}
	}

	&.circle-captionless {
		opacity: .5;

		g {
			path:not(:first-child) {
				display: none;
			}
		}
	}
}

