.panel {
	display: block;
	text-decoration: none;
	padding: 2rem;
	text-align: center;
	margin: 0 0 1rem;
	width: 100%;
	color: #fff;

	@include media-breakpoint-up(md) {
		width: 32%;
	}

	&--support {
		background-color: $brand-silver;
		color: #fff;
		transition: background-color 0.3s ease;

		&:hover {
			background-color: $brand-darkgrey;
		}
	}

	&__container {
		display: flex;
		justify-content: space-between;
		margin: 0 0 2rem;
		flex-wrap: wrap;

		@include media-breakpoint-up(md) {
			justify-content: space-around;
			flex-wrap: no-wrap;
		}
	}

	&__heading {
		margin-top: 0;

		@include media-breakpoint-up(md) {
			min-height: 3em;
		}

		@include media-breakpoint-up(xl) {
			min-height: initial;
		}
	}

	&__icon {
		display: block;
		margin: 2rem auto;
		width: 60px;
		max-width: 100%;
	}
}

a.panel {
	color: #fff;
}
