.contact-map {
	position: relative;
	margin: 0 auto;
	padding-block-end: 40px;

	@include media-breakpoint-up(md) {
		width: 100%;
	}
	@include media-breakpoint-up(lg) {
		width: 92%;
	}
	@include media-breakpoint-up(xl) {
		width: 85%;
		max-width: 1030px;
	}

	.map-holder {
		position: relative;
		height: 654px;
		background-image: url(/images/md-map-v4.png);
		background-position: top center;
		background-repeat: no-repeat;

		.map {
			opacity: 0;
			border: 2px solid transparent;
			transition: all 320ms ease;

			&.show {
				opacity: 1;
				border-color: $brand-darkgrey;
			}
		}
	}

	.map-rollover-wrapper {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: center;
		pointer-events: auto;
		transition: all .75s ease;
	}

	.map-autocomplete-label {
		position: relative;
		display: block;
		margin-inline: auto;
		width: fit-content;
		margin-block-end: 20px;

		.search-icon {
			position: absolute;
			top: 50%;
			right: 12px;
			width: 16px;
			height: 16px;
			filter: brightness(0.5);
			transform: translateY(-50%);
		}
	}

	.map-autocomplete-input {
		position: relative;
		padding: 12px 52px 12px 24px;
	}

	.map-address {
		@include media-breakpoint-up(sm) {
			z-index: 1000;
			background: $white;
			border-radius: 5px;
			padding: $gutter;
			position: absolute;
			top: $gutter;
			right: $gutter;
			width: 30%;
		}

		h4 {
			margin: 0 0 $gutter;
			text-transform: none;
		}
	}
}
