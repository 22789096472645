.bg-primary {
	color: $white;
	background: $brand-primary;
}

.bg-secondary {
	color: $white;
	background: $brand-secondary;
}

.bg-grey {
	color: $white;
	background: $grey;
}

.bg-aqua {
	color: $white;
	background: $brand-turquoise;
}

.bg-guest {
	color: $white;
	background: $brand-guest;
}

.bg-plain-lightgrey {
	background: $lightgrey;
}

.bg-lightgrey {
	background: $lightgrey;
	position: relative;

	&::before,
	&::after {
		position: absolute;
		content: '';
		width: 100%;
		height: 20px;
		left: 0;
		right: 0;
	}

	&::before {
		top: 0;
		background: linear-gradient(to bottom, $white 0%, rgba($lightgrey, 0) 100%);
	}

	&::after {
		bottom: 0;
		background: linear-gradient(to bottom, rgba($lightgrey, 0) 0%, $white 100%);
	}

	&.bg-hexes {
		padding: 75px 0;
		background: $lightgrey url('/images/bg-hexes.png') center / cover no-repeat;
	}

	+ .dotted-arrow {
		&::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 20px;
			left: 0;
			right: 0;
			background: linear-gradient(to bottom, rgba($lightgrey, 0) 0%, $white 100%);
			top: -20px;
		}
	}
}

.bg-white {
	background: $white;
}
