.timeline {
	text-align: center;

	img {
		width: 250px;
		margin: 0 auto 200px;
		@include media-breakpoint-up(md) {
			margin: 0 auto 150px;
		}
	}

	.timeline-step {
		background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 10'><circle fill='#{$brand-tertiary}' cx='1.5' cy='1.5' r='1.5'/></svg>") center / 3px 10px repeat-y;
		cursor: pointer;

		&.slick-current {
			cursor: default;
		}
	}

	.timeline-years {
		position: relative;
		background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'><circle fill='#{$brand-tertiary}' cx='1.5' cy='1.5' r='1.5'/></svg>") 50% 17.5px / 10px 3px repeat-x;
		margin: -200px 0 130px;
		@include media-breakpoint-up(md) {
			margin: -150px 0 80px;
		}
	}

	.timeline-year {
		cursor: pointer;
		position: relative;
		padding: 45px 0 0;
		transition: padding 500ms;

		&::after {
			content: '';
			position: absolute;
			top: 4.5px;
			left: 50%;
			width: 30px;
			height: 27px;
			transform: translateX(-50%);
			background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'><path fill='#{$brand-secondary}' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/></svg>");
			transition: height 500ms, width 500ms;
		}

		&.slick-current {
			font-weight: 700;
			font-size: 20px;
			padding: 80px 0 0;
			transition: padding 500ms;

			&::after {
				content: '';
				position: absolute;
				top: 0;
				left: 50%;
				width: 40px;
				height: 36px;
				transform: translateX(-50%);
				background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 18'><path fill='#{$brand-primary}' d='M6.26 18a2.84 2.84 0 0 1-2.21-1.27L.31 10.28a2.82 2.82 0 0 1 0-2.55l3.74-6.45A2.84 2.84 0 0 1 6.26.01h7.48a2.84 2.84 0 0 1 2.21 1.27l3.74 6.45a2.82 2.82 0 0 1 0 2.55l-3.74 6.45A2.84 2.84 0 0 1 13.74 18z'/></svg>");
				transition: height 500ms, width 500ms;
			}
		}
	}

	.timeline-controls {
		top: 55%;

		.slider-prev,
		.slider-next {
			background-color: $white;
		}
	}
}
