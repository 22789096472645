.form {
	label,
	input {
		display: block;
	}

	input,
	textarea,
	select {
		width: 100%;
	}

	label {
		margin: 0 0 5px;
	}

	input,
	textarea {
		padding: 5px;
		border-radius: 5px;
		border: 2px solid $brand-tertiary;
	}

	input[type="checkbox"] {
		display: inline-block;
		width: auto;
		margin: 0 10px 0 0;
		position: relative;
		top: -2px;
	}

	li {
		margin: 0 0 10px;
	}
}
