.js-hover {
	cursor: pointer;
}

.js-bg-img {
	visibility: hidden;
}

.hide {
	@include media-breakpoint-only(xs) {
		&-xs {
			display: none !important;
		}
	}
	@include media-breakpoint-only(sm) {
		&-sm {
			display: none !important;
		}
	}
	@include media-breakpoint-only(md) {
		&-md {
			display: none !important;
		}
	}
	@include media-breakpoint-only(lg) {
		&-lg {
			display: none !important;
		}
	}
	@include media-breakpoint-only(xl) {
		&-xl {
			display: none !important;
		}
	}
	@include media-breakpoint-only(xxl) {
		&-xxl {
			display: none !important;
		}
	}
}

.hidden {
	display: none !important;
}

.fullheight {
	height: 100%;
	flex: 1;
}

.img-fluid {
	width: 100%;
	max-width: 100%;
	height: auto;
	display: block;
}

.list-reset {
	margin: 0;
	padding: 0;
	list-style: none;
}

.list-standard {
	margin: $gutter/2 0;
	padding: 0;
	list-style: none;
}

.list-inline {
	font-size: 0;

	li {
		display: inline-block;
		font-size: $base-font-size;
	}
}

.valign {
	position: relative;

	.align {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
	}
}

@include media-breakpoint-up(md) {
	.valign-md {
		position: relative;

		.align {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}

.gutter-b.gutter-b {
	margin-bottom: $gutter;
}

.gutter-b-xl.gutter-b-xl {
	margin-bottom: $gutter*4;
}

.padding {
	padding: $gutter;
}

.padding-v {
	padding: $gutter 0;
}

.padding-sm {
	padding: $gutter*2 0;
}

.padding-md {
	padding: $gutter*3 0;
}

.padding-xl {
	padding: $gutter*7 0;
}

.text-center {
	text-align: center;
}

.video-container {
	position: relative;
	padding: 56% 0 0;

	iframe {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}

.dotted {
	margin: $gutter auto;
	border: 0;
	height: 3px;
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'><circle fill='#{$white}' cx='1.5' cy='1.5' r='1.5'/></svg>") center repeat-x;
	width: 30%;
	
	&--primary {
		background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 3'%3E%3Ccircle fill='rgba(#{red($brand-primary)}, #{green($brand-primary)}, #{blue($brand-primary)}, .99999)' cx='1.5' cy='1.5' r='1.5'/%3E%3C/svg%3E") center repeat-x !important;
	}

	&--tall {
		margin: $gutter*2 auto;
	}

	&.dotted-quarter {
		width: 30%;
	}
}

.arrow {
	margin: auto;
	padding: $gutter*2 0;
	border: 0;
	height: 27px;
	background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 27'><path fill='#{$lightgrey}' d='M50 3.27L46.55 0 25 20.45 3.45 0 0 3.27 25 27l3.45-3.27L50 3.27z'/></svg>") center / 50px 27px no-repeat;
}

.dotted-arrow {
	position: relative;
	padding: $gutter*7 0;

	&::after {
		content: '';
		position: absolute;
		width: 100%;
		z-index: 10;
		height: 193px;
		bottom: -111.5px;
		left: 0;
		background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33 193'><circle fill='#{$brand-tertiary}' cx='16.5' cy='1.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='11.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='21.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='31.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='41.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='51.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='61.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='71.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='81.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='91.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='101.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='111.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='121.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='131.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='141.5' r='1.5'/><circle fill='#{$brand-tertiary}' cx='16.5' cy='151.5' r='1.5'/><path fill='#{$brand-tertiary}' d='M16.5,160A16.5,16.5,0,1,0,33,176.5,16.5,16.5,0,0,0,16.5,160Zm1.35,23.07-1.35,1.35-1.35-1.35L5,172.93l1.35-1.35L16.5,181.72l10.15-10.15L28,172.93Z'/></svg>") center no-repeat;
	}
}

.box-narrow {
	@include media-breakpoint-up(md) {
		margin: 0 2.5%;
	}
	@include media-breakpoint-up(lg) {
		margin: 0 12.5%;
	}
	@include media-breakpoint-up(xl) {
		margin: 0 17.5%;
	}
}
