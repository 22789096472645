.social-share-bar {
	ul {
		padding: 0;
		list-style: none;
		font-size: 0;
		margin: 40px 0 0;
	}

	&__item {
		position: relative;
		font-size: 18px;
		margin: 0 0 0 10px;
		display: inline-block;
		vertical-align: middle;

		&:first-of-type {
			margin: 0;
		}
	}

	a {
		vertical-align: middle;

		&:hover {
			cursor: pointer;
		}
	}

	svg {
		width: 30px;
		height: 30px;
		transition: all 500ms;
		background: 0;
		margin: 0 0 -7px;

		path {
			fill: $brand-secondary;
		}

		&:hover {
			transition: all 500ms;

			path {
				fill: $brand-turquoise;
			}
		}
	}
}
