.slider {
	position: relative;
	max-width: 75%;
	margin: auto;

	.slider-controls {
		position: absolute;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
		height: 33px;
		z-index: 1000;

		.slider-prev,
		.slider-next {
			position: absolute;
			height: 33px;
			width: 33px;
			cursor: pointer;
		}

		.slider-prev {
			left: 0;
			background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33 33'><path fill='#{$brand-tertiary}' d='M16.5 31.25A14.75 14.75 0 1 1 31.25 16.5 14.77 14.77 0 0 1 16.5 31.25m0 1.75A16.5 16.5 0 1 0 0 16.5 16.5 16.5 0 0 0 16.5 33z'/><path fill='#{$brand-tertiary}' d='M20.87 25.77L11.6 16.5l9.27-9.26L19.63 6l-9.26 9.27-1.24 1.23 1.24 1.23L19.63 27l1.24-1.23z'/></svg>") center;
		}

		.slider-next {
			right: 0;
			background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 33 33'><path fill='#{$brand-tertiary}' d='M16.5 1.75A14.75 14.75 0 1 1 1.75 16.5 14.77 14.77 0 0 1 16.5 1.75m0-1.75A16.5 16.5 0 1 0 33 16.5 16.5 16.5 0 0 0 16.5 0z'/><path fill='#{$brand-tertiary}' d='M12.13 7.24l9.27 9.26-9.27 9.27L13.37 27l9.26-9.27 1.24-1.23-1.24-1.23L13.37 6l-1.24 1.24z'/></svg>") center;
		}
	}

	.slider-slide {
		text-align: center;
		@include media-breakpoint-up(md) {
			padding: 0 $gutter*3;
		}

		.slide-caption {
			font: 18px $exo;
			@include media-breakpoint-up(md) {
				font: 24px $exo;
			}
		}
	}
}
