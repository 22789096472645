.button {
	display: inline-block;
	padding: 4.5px 19px;
	text-decoration: none;
	font: 21px $exo;
	border-radius: 19px;
	border: 1px solid $white;
	background: $white;
	color: $brand-primary;
	cursor: pointer;
	transition: all 500ms;

	&:hover {
		background: $brand-primary;
		color: $white;
		transition: all 500ms;
	}

	&.button-primary {
		border-color: $brand-primary;
		background: $brand-primary;
		color: $white;

		&:hover {
			background: $white;
			color: $brand-primary;
		}

		&-outline {
			border-color: $brand-primary;
			background: $white;
			color: $brand-primary;

			&:hover {
				background: $brand-primary;
				color: $white;
			}
		}
	}

	&.button-secondary {
		border-color: $brand-secondary;
		background: $brand-secondary;
		color: $white;

		&:hover {
			background: $white;
			color: $brand-secondary;
		}

		&-outline {
			border-color: $brand-secondary;
			background: $white;
			color: $brand-secondary;

			&:hover {
				background: $brand-secondary;
				color: $white;
			}
		}
	}

	&.button-tertiary {
		border-color: $brand-tertiary;
		background: $brand-tertiary;
		color: $white;

		&:hover {
			background: $white;
			color: $brand-tertiary;
		}

		&-outline {
			border-color: $brand-tertiary;
			background: $white;
			color: $brand-tertiary;

			&:hover {
				background: $brand-tertiary;
				color: $white;
			}
		}
	}

	&.button-aqua {
		border-color: $brand-turquoise;
		background: $brand-turquoise;
		color: $white;

		&:hover {
			background: $white;
			color: $brand-turquoise;
		}

		&-outline {
			border-color: $brand-turquoise;
			background: $white;
			color: $brand-turquoise;

			&:hover {
				background: $brand-turquoise;
				color: $white;
			}
		}
	}

	&.button-white {
		border-color: $white;
		background: $white;
		color: $brand-turquoise;

		&:hover {
			background: $brand-turquoise;
			color: $white;
		}

		&-outline {
			border-color: $white;
			background: $brand-turquoise;
			color: $white;

			&:hover {
				background: $white;
				color: $brand-turquoise;
			}
		}
	}

	&.button-big {
		font-size: 1.6em;
	}

	&.button-xl {
		font-size: 2em;
		padding: 9px 25px;
	}
}

.cta-area {
	margin: 0 auto;
	text-align: center;
	padding: $gutter 0;
	@include media-breakpoint-up(md) {
		width: 95%;
	}
	@include media-breakpoint-up(lg) {
		width: 75%;
	}
	@include media-breakpoint-up(xl) {
		width: 65%;
	}

	&.cta-area-large {
		padding: 75px 0;
	}
}
