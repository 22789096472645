.vid-block {

    &__wrapper {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
    }

    &__iframe {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}

.vid-block + .img-block {
    margin-top: 20px;
}