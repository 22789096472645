header {
	background: $brand-secondary;
	color: $white;
	padding: 0 $gutter;
	display: flex;
	font-family: $exo;
	position: relative;

	.supportGB &,
	.supportFR & {
		background: $brand-turquoise;
	}

	@include media-breakpoint-down(md) {
		flex-wrap: wrap;
		align-items: center;
		justify-content: space-between;
	}

	.logo {
		margin: 28px 0;
		flex: 0 0 75%;
		@include media-breakpoint-up(sm) {
			flex: 0 0 45%;
		}
		@include media-breakpoint-up(md) {
			flex: 0 0 30%;
		}
		@include media-breakpoint-up(lg) {
			flex: 0 0 22.5%;
		}
		@include media-breakpoint-up(xl) {
			flex: 0 0 18%;
		}
		@include media-breakpoint-up(xxl) {
			flex: 0 0 15%;
		}
	}

	.nav-toggle {
		cursor: pointer;
	}

	nav {
		text-align: center;
		padding: 0 0 26px;
		@include media-breakpoint-down(md) {
			position: absolute;
			top: 100%;
			display: none;
			width: 100vw;
			margin: 0 -#{$gutter};
			background: rgba(255, 255, 255, .9);
			z-index: 1000;
		}
		@include media-breakpoint-up(lg) {
			flex: 0 0 77.5%;
			text-align: right;
			display: flex !important;
			flex-direction: column;
			justify-content: space-between;
		}
		@include media-breakpoint-up(xl) {
			flex: 0 0 82%;
		}
		@include media-breakpoint-up(xxl) {
			flex: 0 0 85%;
		}

		.header-menu {
			margin: 6px 0 10px;

			li {
				font-size: 18px;
				@include media-breakpoint-down(md) {
					display: block;

					&.social {
						display: inline-block;
						margin: 0 5px;
						position: relative;
					}
				}
				@include media-breakpoint-up(lg) {
					margin: 0 0 0 30px;
				}

				&.social {
					@include media-breakpoint-up(lg) {
						margin: 0 0 0 20px;
						position: relative;
						top: 8px;

						&--support {
							top: 0;
						}
					}

					svg {
						width: 30px;
						height: 30px;
						transition: background 500ms;

						path {
							fill: $brand-secondary;

							.supportGB &,
							.supportFR & {
								fill: $brand-lightgrey;
							}
						}

						&:hover {
							transition: background 500ms;
							path {
								fill: rgba(255, 255, 255, .75);

								.supportGB,
								.supportFR & & {
									fill: #fff;
								}
							}
						}
					}
				}
			}

			a {
				text-decoration: none;
				transition: color 500ms;
				color: $base-font-color;
				@include media-breakpoint-up(lg) {
					color: $white;
				}

				&:hover,
				&.active {
					color: $brand-primary;
					transition: color 500ms;
				}
			}

			.supportGB & a,
			.supportFR & a {
				color: #fff;

				&:hover,
				&.active {
					color: $brand-darkgrey;
					transition: color 500ms;
				}
			}
		}
	}

	.header-buttons {
		margin: 0 0 $gutter;
		@include media-breakpoint-up(lg) {
			margin: 0 0 35px;
		}

		li {
			margin: 2px;
			font-size: 14px;
			@include media-breakpoint-up(lg) {
				margin: 0 0 0 4px;
			}

			&:nth-of-type(2) {
				a {
					background: #62bec4;
				}

				.supportGB & a,
				.supportFR & a {
					background: $brand-darkgrey;
				}
			}

			&:nth-of-type(3) {
				a {
					background: #929b9f;
				}
			}
		}

		a {
			text-align: center;
			background: $brand-tertiary;
			color: $white;
			padding: 10px 5px;
			text-decoration: none;
			display: inline-block;

			&:hover {
					opacity: 0.9;
			}

			@include media-breakpoint-up(lg) {
				border-radius: 0 0 3px 3px;
			}
		}
	}

	.guest-buttons {
		&--mobile {
			margin: -2px auto 0;
			@include media-breakpoint-up(md) {
				display: none!important;
			}
		}

		&--desktop {
			display: none;
			@include media-breakpoint-up(md) {
				display: block;
			}
		}
	}

	.support-page-nav {
		margin: 0;
		//padding-top: 2rem;
		
		li {
			display: inline-block;
		}
	}

	+ .js-anim-trigger {
		margin-top: $gutter*2;
	}
}


.header-menu {
	.social {
		&:hover {
			.social__tooltip {
				opacity: 1;
				pointer-events: all;
				transform: translate(-50%, calc(100% + 10px));
			}
		}

		&__tooltip {
			opacity: 0;
			pointer-events: none;
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translate(-50%, calc(90% + 10px));
			background: #62bec4;
			padding: 6px 8px;
			text-align: center;
			z-index: 1;
			width: max-content;
			border-radius: 3px;
			transition: 0.2s;

			&::before {
				content: "■";
				position: absolute;
				top: 0;
				left: 50%;
				transform: translate(-50%, -50%) rotate(45deg);
				color: #62bec4;
				font-size: 16px;
			}

			p {
				margin: 0;
				display: block;
				color: #fff;
				font-size: 14px;
			}
		}
	}
}