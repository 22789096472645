.js-icon-list {
	li {
		opacity: 0;
	}
}

.js-slidefade {
	.js-animate {
		transform: translateY(50%);
		opacity: 0;
		transition: all 950ms;

		&.animated {
			transform: translateY(0);
			opacity: 1;
			transition: all 750ms;
		}
	}
}
