.cookie-bar {
	color: #fff;
	display: none;
	font-size: 0;
	background: $brand-darkgrey;
	padding: 10px;
	text-align: center;

	p,
	.button {
		font-size: $base-font-size;
		display: inline-block;
		vertical-align: middle;
	}

	p {
		max-width: 80%;
		margin: 0 15px 0 0;
	}

	a {
		color: #fff;
	}
}
