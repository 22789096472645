.home {
	background: url(/images/bg-home-mobile.jpg) top center / contain no-repeat;
	@include media-breakpoint-up(md) {
		background: url(/images/bg-home.jpg) top center / contain no-repeat;
	}

	header {
		background: transparent;
	}

	.hero {
		color: $white;

		.arrow-down {
			width: 55px;
			transform: rotate(90deg) translateX(200%);
			opacity: 0;
		}

		&.slide-up .arrow-down {
			animation: slideUp 0.7s;
			animation-fill-mode: forwards;
		}
	}

	@keyframes slideUp {
		0% {
			transform: rotate(90deg) translateX(200%);
			opacity: 0;
		}

		100% {
			transform: rotate(90deg) translateX(0%);
			opacity: 1;
		}
	}
}
