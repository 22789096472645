.tabs {
	border: 1px solid $brand-primary;

	.tab-controls {
		li {
			background: $brand-secondary;
			color: $white;
			padding: $gutter;
			cursor: pointer;
			display: block;
			@include media-breakpoint-up(md) {
				width: 20%;
				display: inline-block;
			}
			@include resp-font-size(18, 18, 16, 20, 24);

			&.active {
				background: $lightgrey;
				color: $brand-secondary;
			}
		}
	}

	.tab-content {
		background: $lightgrey;
		padding: 50px $gutter / 2;
		display: none;

		&:first-of-type {
			display: block;
		}

		@include media-breakpoint-down(md) {
			.columns {
				margin: 0;
			}
		}
	}
}
