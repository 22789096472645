/* Bootstrap media queries
/* Breakpoint min/max are set in the _vars.scss file

/* Mixin: Media Min Width
Usage:
.foo {
	@include media-breakpoint-up(sm) {
		color: #f00;
	}
}
*/
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	}
	@else {
		@content;
	}
}

/* Mixin: Media Max Width
Usage:
.foo {
	@include media-breakpoint-down(md) {
		color: #f00;
	}
}
*/
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	}
	@else {
		@content;
	}
}

/* Mixin: Media Single Breakpoint
Usage:
.foo {
	@include media-breakpoint-only(md) {
		color: #f00;
	}
}
*/
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
	@include media-breakpoint-up($name, $breakpoints) {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

/* Mixin: Media Between Breakpoints
Usage:
.foo {
	@include media-breakpoint-between(sm, lg) {
		color: #f00;
	}
}
*/
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
	@include media-breakpoint-up($lower, $breakpoints) {
		@include media-breakpoint-down($upper, $breakpoints) {
			@content;
		}
	}
}

/* Mixin for font declaration syntax
Usage: @include declare-font-face('Gotham', 'gotham');
*/
@mixin declare-font-face($font-family, $font-filename, $font-weight: normal, $font-style: normal, $font-stretch: normal) {
	@font-face {
		font-family: '#{$font-family}';
		src: url('/fonts/#{$font-filename}.woff2') format('woff2'),
			url('/fonts/#{$font-filename}.woff') format('woff');
		font-weight: $font-weight;
		font-style: $font-style;
		font-stretch: $font-stretch;
	}
}

/* Mixin: Font size
Usage: 	@include font-size(14);
		@include font-size(32px);
*/
@mixin font-size($size) {
	@if unitless($size) {
		$size: $size * 1px;
	}
	font-size: $size;
	font-size: ($size / $base-font-size) * 1rem;
}

/* Mixin: Font face
Usage: @include font-face('gotham', '/fonts/gotham');
*/
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
	@font-face {
		font-family: quote($font-name);
		src: url($file-name + '.eot');
		src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
			url($file-name + '.woff') format('woff'),
			url($file-name + '.ttf')  format('truetype'),
			url($file-name + '.svg##{$font-name}')  format('svg');
		font-weight: $weight;
		font-style: $style;
	}
}

/* Mixin: Responsive font sizing
Usage:
// Sets every breakpoint to have a different font size
.foo {
	@include resp-font-size(22, 24, 28, 36, 48);
}

// Sets xs, sm and md to have different font sizes,
// lg and xl will then inherit the md size
.bar {
	@include resp-font-size(18, 20, 24)
}
*/
/* stylelint-disable */
@mixin resp-font-size($xs, $sm: $xs, $md: $sm, $lg: $md, $xl: $lg, $xxl: $xl) {
	@each $break, $size in (xs: $xs, sm: $sm, md: $md, lg: $lg, xl: $xl, xxl: $xxl) {
		@if unitless($size) {
			$size: $size * 1px;
		}
		@include media-breakpoint-up($break) {
			font-size: $size;
		}
	}
}
/* stylelint-enable */

/* Mixin: Container breakpoints
Usage:
.container {
	@include make-container();
}
*/
@mixin make-container() {
	@each $break, $width in (sm: map-get($container-max-widths, sm), md: map-get($container-max-widths, md), lg: map-get($container-max-widths, lg), xl: map-get($container-max-widths, xl), xxl: map-get($container-max-widths, xxl)) {
		@include media-breakpoint-up($break) {
			width: $width;
		}
	}
}

/* Mixin: Make Grid
Usage:
.columns-two {
	@include make-grid(2);
}
*/
@mixin make-grid($columns) {
	@each $break, $width in (sm: map-get($container-max-widths, sm), md: map-get($container-max-widths, md), lg: map-get($container-max-widths, lg), xl: map-get($container-max-widths, xl), xxl: map-get($container-max-widths, xxl)) {
		@include media-breakpoint-up($break) {
			width: round(($width - (18*($columns - 1))) / $columns);
		}
	}
}

@mixin make-grid-inner($columns) {
	@each $break, $width in (sm: map-get($container-max-widths, sm), md: map-get($container-max-widths, md), lg: map-get($container-max-widths, lg), xl: map-get($container-max-widths, xl), xxl: map-get($container-max-widths, xxl)) {
		@include media-breakpoint-up($break) {
			width: round(($width - (18*($columns))) / $columns);
		}
	}
}

/* Mixin: Make Grid
Usage:
.columns-two-fifths {
	.column:first-of-type {
		@include make-grid-split(2,1,5);
	}
	.column:last-of-type {
		@include make-grid-split(2,4,5);
	}
}
*/
@mixin make-grid-split($columns, $split, $total) {
	@each $break, $width in (sm: map-get($container-max-widths, sm), md: map-get($container-max-widths, md), lg: map-get($container-max-widths, lg), xl: map-get($container-max-widths, xl), xxl: map-get($container-max-widths, xxl)) {
		@include media-breakpoint-up($break) {
			width: round((($width - (18*($columns - 1))) / $total) * $split);
		}
	}
}
